/*--------------------------------------------------------------
## Basic Color
----------------------------------------------------------------*/
$white: #fff;
$black: #000000;
$primary: #06182C;
$secondary: #6C7C8C;
$ternary: #D9D9D9;
$border: #eaeaea;
$gray: #F4F6F9;
$accent: #E38241;
