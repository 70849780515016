.error {
  width: 100%;
  height: 100%;
  overflow: hidden;
  word-wrap: break-word;
  background-color: red;
  color: white;
  padding-left: 50px;
  padding-right: 50px;
  padding-top: 20px;
  padding-bottom: 20px;
  border-radius: 10px;
}

.success {
  width: 100%;
  height: 100%;
  overflow: hidden;
  word-wrap: break-word;
  background-color: green;
  color: white;
  padding-left: 50px;
  padding-right: 50px;
  padding-top: 20px;
  padding-bottom: 20px;
  border-radius: 10px;
}

.ether-link {
  text-decoration: underline;
}

button[disabled] {
  background-color: #ccc;
  cursor: not-allowed;
}
